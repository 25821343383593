window.initSwiper = function () {
  var swiper = [];
  $('.swiper-container').each(function (index) {
    var $el = $(this);

    swiper[index] = $el.swiper({
      speed: 1000,
      pagination: $el.find('.swiper-pagination')[0],
      paginationClickable: true,
      autoplay: 7000,
      autoplayDisableOnInteraction: false,
      simulateTouch: false
    });

    $el.find('.prev-slide').on('click', function () {
      swiper[index].swipePrev();
    });

    $el.find('.next-slide').on('click', function () {
      swiper[index].swipeNext();
    });

    positionSwipperBullets();

    $(window).resize(function () {
      updateSwipers();
      positionSwipperBullets();
    });
  });
}

$( "#map-overlay" ).click(function() {
  $( "#map-overlay" ).css("visibility","hidden");
});    
  
$( "#map-canvas" ).mouseleave(function() {
  $( "#map-overlay" ).css("visibility","visible");
});

var geocoder;
var map;

window._initGoogleMapsTimeout = 0;

window.initGoogleMaps = function () {
  if (!window.hasOwnProperty('google') || !google) {
    if (window._initGoogleMapsTimeout++ > 20) {
      return console.error("Failed to load Google Maps!");
    }

    return setTimeout(initGoogleMaps, 300);
  }

  geocoder = new google.maps.Geocoder();
  
  var latlng = new google.maps.LatLng(0,0);
  var zoom = $('#map-canvas').data('zoom');

  var mapOptions = {
      zoom: zoom,
      center: latlng,
      disableDefaultUI: true
  }

  map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);

  var address = $('#map-canvas').data('address');
  
  geocoder.geocode( { 'address': address}, function(results, status) {
      
      if (status == google.maps.GeocoderStatus.OK) {
          
          map.setCenter(results[0].geometry.location);
          
          var marker = new google.maps.Marker({
              map: map,
              position: results[0].geometry.location
          });
          
      } else {
          
          console.log('Geocode was not successful for the following reason: ' + status);
          
      }
  });
}

$(document).ready(initGoogleMaps);
