/* TAP ANIMATION */
$(function () {
  setTimeout(function () {
    $('#tap-teaser-container-wrapper').css('display', 'block');

    if (!$('body').hasClass('neos-backend')) {
      anime({
        targets: [
          '#tap-animation--flower',
          '#tap-animation--letter-t',
          '#tap-animation--circle-1',
          '#tap-animation--letter-a',
          '#tap-animation--circle-2',
          '#tap-animation--letter-p',
          '#tap-animation--subtitle',
        ],
        delay: anime.stagger(50),
        duration: 2000,
        easing: 'spring(1, 88, 11, 0)',
        top: 0
      }).finished.then(function () {
        setTimeout(function () {
          [
            '#tap-animation--letter-t',
            '#tap-animation--circle-1',
            '#tap-animation--letter-a',
            '#tap-animation--circle-2',
            '#tap-animation--letter-p',
            // '#tap-animation--subtitle',
          ].forEach(function (elem, index) {
            anime.timeline({
              targets: elem,
              delay: (index * 50),
              loop: true,
              endDelay: 0,
              duration: 350,
            }).add({
              top: -10,
              easing: 'easeInCubic'
            }).add({
              top: 0,
              easing: 'easeOutCubic'
            }).add({}, 10000);
          });
        }, 5000);
      });
    }
  }, 2500);
});
/* END TAP ANIMATION */